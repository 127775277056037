import styles from 'components/objects/forms/SanaForm.module.scss';
import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginWithSingleSignOn, resetLoginFailed } from 'behavior/user';
import { SanaButton } from 'components/primitives/buttons';
import LoginFailedMessage from './SsoLoginFailedMessage';
import { useHandlerLockerInPreview } from 'components/objects/preview';

const SingleSignOnButton = ({ dispatch }) => {
  const onClick = useHandlerLockerInPreview(useCallback(() => {
    dispatch(loginWithSingleSignOn());
  }, []));

  useEffect(() => () => dispatch(resetLoginFailed()), []);

  return (
    <div>
      <LoginFailedMessage />
      <SanaButton textKey="Login" type="button" onClick={onClick} className={`${styles.btn}`} />
    </div>
  );
};

SingleSignOnButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(({ settings: { profile } }) => ({ showRememberMe: profile && profile.showRememberMe }))(
  SingleSignOnButton,
);
